<template>
  <div class="d-flex justify-content-center">
    <b-row
      class="col-12 col-lg-8 col-xl-5"
    >
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t("creator.useIA") }}</h1>
        <p>{{ $t("creator.TellService") }}</p>

        <div :class="`${!urlStateExpression(link) ? 'border-link-danger' : 'border-link-success'} d-flex align-items- w-100`">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon
                icon="LinkIcon"
                :class="!urlStateExpression(link) ? 'text-invalid' : 'text-success'"
              />
            </b-input-group-prepend>
            <b-form-input
             v-model="link" 
             class="input-link h-100"
             autocomplete="off"
             :placeholder="$t('creator.productUrl')"
             ></b-form-input>
          </b-input-group>
        </div>
        <small v-if="!urlStateExpression(link)" class="text-invalid">{{ $t('creator.enterUrl') }}</small>

        <b-form-textarea rows="1" max-rows="15" class="mt-2" v-model="brief" :state="state_brief" :placeholder="$t('creator.context')" aria-describedby="invalid-feedback-brief"></b-form-textarea>
        <b-form-invalid-feedback id="invalid-feedback-brief">
          <span v-if="brief && brief.length >= 2000">{{ $t('creator.2000characters') }}</span>
        </b-form-invalid-feedback>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button
            variant="blue-button-next-step"
            class="blue-button-next-step my-2"
            :disabled="!urlStateExpression(link) || !state_brief"
            @click="save()"
            >{{ $t("creator.butonNext") }}</b-button
          >
        </b-col>
        <small class="mt-2 w-100 text-center text-muted">{{
          $t("creator.requiredCharacters")
        }}</small>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormTextarea,
  BInputGroupPrepend,
  BInputGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { urlStateExpression } from '@/libs/utils/urls'

export default {
  name: "stepCastingCallIA",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroup,
    BFormInvalidFeedback,
  },
  data() {
    return {
      urlStateExpression,
      link: "",
      steps: {},
      brief: ""

    };
  },
  created() {
    this.getData()
  },
  computed: {
    state_brief() {
      if (this.brief) return this.brief.length > 10 && this.brief.length <= 2000
      else return false
    },
  },
  methods: {
    getData() {
      this.steps = JSON.parse(localStorage.getItem('steps')) === null ? {} : JSON.parse(localStorage.getItem('steps'));
      if (this.steps.castingIA.link) this.link = this.steps.castingIA.link;
      if (this.steps.castingIA.brief) this.brief = this.steps.castingIA.brief;
    },
    save() {
      if (this.link && !/^https?:\/\//i.test(this.link)) {
        this.link = `https://${this.link}`;
      }
      this.steps['castingIA'] = {};
      this.steps['castingIA'].link = this.link;
      this.steps['castingIA'].brief = this.brief;

      // Guardar en localStorage
      localStorage.setItem("steps", JSON.stringify(this.steps));
      this.$router.push({ name: "steps_casting_call", params: { step: "cover-image" } });
    }
  },
};
</script>
<style scoped>
.text-invalid {
  color: #496daa;
}
.border-link-danger .input-group-prepend .input-group-text {
  border-right: 0;
  border-color: #496daa;
}
.border-link-success .input-group-prepend .input-group-text {
  border-right: 0;
  border-color: #28C76F;
}
</style>