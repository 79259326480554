<template>
  <layout-stepper :key="current_step">
    <template #body>
      <div class="d-flex">
        <b-col class="col-6 text-start">
          <b-link @click="makeRedirect()" v-if="has_prev_step" class="link-back">
            <feather-icon icon="ChevronLeftIcon"></feather-icon>
            {{ $t('creator.previousStep') }}
          </b-link>
        </b-col>
      </div>
      <div>
        <step-select-service v-if="current_step === 'select-service'"/>
        <step-service-options v-if="current_step === 'service-options'"/>
        <step-organization v-if="current_step === 'organization'"/>
        <step-organization-search v-if="current_step === 'organization-search'"/>
        <step-title v-if="current_step === 'title-casting-call'"/>
        <step-cover-image v-if="current_step === 'cover-image'"/>
        <step-preview-card v-if="current_step === 'preview-card'"/>
        <step-description v-if="current_step === 'description-casting-call'"/>
        <step-visibility v-if="current_step === 'visibility'"/>
        <step-location v-if="current_step === 'location'"/>
        <step-delivery-deadline v-if="current_step === 'delivery-deadline'"/>
        <step-tags v-if="current_step === 'tags'"/>
        <step-attachment-files v-if="current_step === 'attachment-files'"/>
        <step-compensation v-if="current_step === 'compensation'"/>
        <step-information-request v-if="current_step === 'information-request'"/>
        <step-casting v-if="current_step === 'casting-ia'"/>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BLink,
  BCol,
} from 'bootstrap-vue';

import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import stepSelectService from './stepSelectService.vue';
import stepServiceOptions from './stepServiceOptions.vue';
import stepOrganization from './stepOrganization.vue';
import stepTitle from './stepTitle.vue';
import stepCoverImage from './stepCoverImage.vue';
import stepVisibility from './stepVisibility.vue';
import stepDescription from './stepDescription.vue';
import stepDeliveryDeadline from './stepDeliveryDeadline.vue';
import stepTags from './stepTags.vue';
import stepAttachmentFiles from './stepAttachmentFiles.vue';
import stepCompensation from './stepCompensation.vue';
import stepInformationRequest from './stepInformationRequest.vue';
import stepOrganizationSearch from './stepOrganizationSearch.vue';
import StepPreviewCard from './stepPreviewCard.vue';
import stepCasting from './stepCasting.vue';

export default {
  name: 'settingCastingCallDashboard',
  components: {
    BLink,
    BCol,
    stepSelectService,
    stepServiceOptions,
    stepOrganization,
    stepTitle,
    stepCoverImage,
    stepVisibility,
    stepDescription,
    stepDeliveryDeadline,
    stepTags,
    stepCasting,
    stepAttachmentFiles,
    stepCompensation,
    stepInformationRequest,
    layoutStepper,
    stepOrganizationSearch,
    StepPreviewCard,
    stepLocation: () => import('./stepLocation.vue')
  },
  data() {
    return {
      has_prev_step: false,
      current_step: this.$route.params.step,
      steps: {},
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    this.has_prev_step = this.current_step !== 'select-service';
    if (this.has_prev_step) this.getStep();
  },
  methods: {
    getStep() {
      this.steps = JSON.parse(localStorage.getItem('steps')) === null ? {} : JSON.parse(localStorage.getItem('steps'));
    },  
    makeRedirect() {
      if (!this.steps || !this.steps.content_type) return;

      if (this.steps.content_type.value === "create_casting_ia") {
        const ia_steps = {
          'organization-search': 'select-service',
          'organization': 'select-service',
          'casting-ia': 'organization-search',
          'cover-image': 'casting-ia'
        };
        if (ia_steps[this.current_step]) {
          this.$router.push({ name: 'steps_casting_call', params: { step: ia_steps[this.current_step] } });
          return;
        }
      }

      const general_steps = {
        'service-options': 'select-service',
        'organization': 'organization-search',
        'organization-search': 'service-options',
        'title-casting-call': 'organization',
        'cover-image': 'title-casting-call',
        'preview-card': 'cover-image',
        'description-casting-call': 'preview-card',
        'visibility': 'description-casting-call',
        'location': 'visibility',
        'delivery-deadline': 'location',
        'tags': 'delivery-deadline',
        'attachment-files': 'tags',
        'compensation': 'attachment-files',
        'information-request': 'compensation'
      };

      if (general_steps[this.current_step]) {
        this.$router.push({ name: 'steps_casting_call', params: { step: general_steps[this.current_step] } });
      }
    },

  },
  watch: {
    '$route.params.step'(new_step) {
      this.current_step = new_step
      if (new_step !== 'select-service') this.has_prev_step = true
      else this.has_prev_step = false
      this.getStep();
    }
  }
}
</script>